import useAxiosPrivate from "./useAxiosPrivate";
import useUserContext from "./useUserContext";

const useAxiosAvatar = () => {
  const axiosPrivate = useAxiosPrivate();
  const { setAvatarURL } = useUserContext();

  const getAvatar = async (avatar, userId) => {
    if (avatar?.type) {
      const avatarType = avatar.type;
      let avatarObj = {
        profile: null,
        nav: null,
      };

      for (var key of Object.keys(avatarObj)) {
        const body = JSON.stringify({
          url: `${userId}_avatar_${key}.${avatarType}`,
        });

        try {
          const ava = await axiosPrivate.put(
            `${process.env.REACT_APP_BACKEND_URL}/user/avatar`,
            body
          );

          if (ava?.data?.status === 1) {
            avatarObj[key] = ava?.data?.url;
          } else {
            avatarObj[key] = null;
          }
        } catch (err) {
          console.log(err);
        }
      }

      setAvatarURL(avatarObj);
    }
  };

  return getAvatar;
};

export default useAxiosAvatar;
