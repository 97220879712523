import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefresh = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    try {
      const response = await axios.get("/refresh", {
        withCredentials: true,
      });
      console.log(response);
      const newAccessToken = response?.data?.accessToken;
      setAuth((prev) => {
        return {
          ...prev,
          accessToken: newAccessToken,
        };
      });
      return newAccessToken;
    } catch (err) {
      console.log(err);
    }
  };

  return refresh;
};

export default useRefresh;
