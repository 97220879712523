import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Routes, Route } from "react-router-dom";

import Profile from "./components/Profile";
import Settings from "./components/Settings";
import AppLayout from "./components/layout/AppLayout";
import Missing from "./components/Missing";

import rolesList from "./config/rolesList";
import RequiredAuth from "./components/RequiredAuth";
import Unauthorized from "./page/Unauthorized";
import BodyLayout from "./components/layout/BodyLayout";
import PersistLogin from "./components/PersistLogin";

const Home = React.lazy(() => import("./page/Home"));
const Track = React.lazy(() => import("./page/Track"));
const Category = React.lazy(() => import("./page/Category"));
const Group = React.lazy(() => import("./page/Group"));
const Login = React.lazy(() => import("./auth/Login"));
const Overview = React.lazy(() => import("./page/Overview"));
const Admin = React.lazy(() => import("./page/Admin"));

function App() {
  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        {/* Private routes */}
        <Route element={<PersistLogin />}>
          <Route element={<BodyLayout />}>
            <Route element={<RequiredAuth allowedRoles={[rolesList.User]} />}>
              <Route path="/" element={<Home />} />
              <Route path="/track" element={<Track />} />
              <Route path="/category" element={<Category />} />
              <Route path="/group" element={<Group />} />
              <Route path="/overview" element={<Overview />} />

              <Route
                element={<RequiredAuth allowedRoles={[rolesList.Admin]} />}
              >
                <Route path="/profile" element={<Profile />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/admin" element={<Admin />} />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* other routes */}
        <Route path="/*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
