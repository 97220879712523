import { BsPenFill } from "react-icons/bs";
import { BiCategoryAlt, BiHome } from "react-icons/bi";
import { VscGroupByRefType } from "react-icons/vsc";
import { RiDashboard2Line } from "react-icons/ri";
import { RiAdminLine } from "react-icons/ri";

export const navList = [
  {
    path: "/",
    content: "Home",
    icon: <BiHome />,
  },
  {
    path: "/track",
    content: "Track",
    icon: <BsPenFill />,
  },
  {
    path: "/category",
    content: "Category",
    icon: <BiCategoryAlt />,
  },
  {
    path: "/group",
    content: "Group",
    icon: <VscGroupByRefType />,
  },
  {
    path: "/overview",
    content: "Overview",
    icon: <RiDashboard2Line />,
  },
  {
    path: "/admin",
    content: "Admin",
    icon: <RiAdminLine />,
  },
];
