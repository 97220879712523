import axios from "../api/axios";
import useAuth from "./useAuth";
import useUserContext from "./useUserContext";

const useLogout = () => {
  const { setAuth } = useAuth();
  const { setUserInfo } = useUserContext();

  const logout = async () => {
    try {
      await axios.get("/logout", {
        withCredentials: true,
      });
      setAuth({});
      setUserInfo({});
    } catch (err) {
      console.log(err);
    }
  };

  return logout;
};

export default useLogout;
